
 import {Component, Prop, Vue, Watch} from "vue-property-decorator" 
import { translations } from '@/mixins'
import { extend } from 'vee-validate'

@Component({
  mixins: [translations]
})
export default class BetaBadge extends Vue {
  translate!: Function
}
